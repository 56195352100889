import { CategoryForm } from "./CreateCategoryForm";
import { UserForm } from "./CreateUserForm";
import { ServiceForm } from "./CreateServiceForm";
import { PreviousClientForm } from "./CreatePreviousClientForm";
import { CompanyForm } from "./CreateCompanyForm";
import { ChangeOrderForm } from "./ChangeOrderForm";

export default {
  CategoryForm,
  ChangeOrderForm,
  UserForm,
  ServiceForm,
  PreviousClientForm,
  CompanyForm,
};
